import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  ActivityIcon,
  BuyIcon,
  CategoryIcon,
  ContactUsIcon,
  GraphIcon,
  HomeIcon,
  JoinUsIcon,
  LockIcon,
  NotificationsIcon,
  O2ELogo,
  ProfileIcon,
  SettingsIcon,
  TicketIcon,
  UserIcon,
  WorkIcon,
  CoffeeOutlined,
  AdvertisingOutlined,
} from 'app/components/icons';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from 'app/routes';
import { Storage, Translate, translate } from 'react-jhipster';
import {
  Breadcrumbs,
  Button,
  Grid,
  Hidden,
  Link,
  Menu,
  Drawer as DrawerMobile,
  MenuItem,
  Toolbar,
  AppBar as AppBarMobile,
  useMediaQuery,
} from '@mui/material';
import useBreadcrumbs, { BreadcrumbMatch } from 'use-react-router-breadcrumbs';
import { useCustomBreadcrumbs } from 'app/hooks/useCustomBreadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PublicIcon from '@mui/icons-material/Public';
import { setLocale } from 'app/shared/reducers/locale';
import { isRTL } from 'app/config/translation';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const drawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  paddingLeft: '2rem',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundPositionY: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '233px 206px',
});

const closedMixin = (theme: Theme): CSSObject => ({
  paddingLeft: '2rem',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(13)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(15)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  padding: '20px',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1.25),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuIcon: {
    fontSize: '1.5rem',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
    },
  },

  toolbar: theme.mixins.toolbar,
  logoContainer: {
    lineHeight: 0,
    background: theme.palette.primary.main,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.common.black,
    color: theme.palette.text.primary,
  },
  drawerPaperLight: {
    width: drawerWidth,
    borderRight: 'none',
    boxShadow: '0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15)',
    top: '64px',
    [theme.breakpoints.down('md')]: {
      top: 0,
    },
  },
  content: {
    width: '100%',
    minHeight: '100vh',
    flexGrow: 1,
    /*padding: theme.spacing(3),*/
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  main: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },
  },
}));

const Layout = ({ children }) => {
  const { routes } = useCustomBreadcrumbs();
  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: true,
    excludePaths: ['/'],
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    navigateTo('/logout');
    setAnchorEl(null);
  };
  const dispatch = useAppDispatch();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const [open, setOpen] = React.useState(true);

  const classes = useStyles();

  const handleLocaleChange = event => {
    const langKey = currentLocale === 'en' ? 'ar-ly' : 'en';
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
    document.querySelector('html').setAttribute('dir', isRTL(langKey) ? 'rtl' : 'ltr');
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState<any>();
  const navigateTo = useNavigate();
  const account = useAppSelector(state => state.authentication.account);
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  React.useEffect(() => {
    setDrawerOpen(matchUpMd);
  }, [matchUpMd]);
  const items = account?.authorities.includes('ROLE_ENTITY_ADMIN')
    ? [
        {
          label: translate('routes.dashboard'),
          title: 'routes.dashboard',
          description: 'routes.dashboardDescription',
          key: paths.home.pathname,
          icon: <ActivityIcon />,
          id: paths.home.pathname,
          onClick: () => navigateTo(paths.home.pathname),
        },
        {
          label: translate('routes.employees'),
          title: 'routes.employees',
          description: 'routes.employeesDescription',
          key: paths.employees.pathname,
          icon: <UserIcon />,
          id: paths.employees.pathname,
          onClick: () => navigateTo(paths.employees.pathname),
        },
        {
          label: translate('routes.giftcards'),
          title: 'routes.giftcards',
          description: 'routes.giftcardsDescription',
          key: paths.giftcards.pathname,
          icon: <TicketIcon />,
          id: paths.giftcards.pathname,
          onClick: () => navigateTo(paths.giftcards.pathname),
        },
        {
          label: translate('routes.catering'),
          title: 'routes.catering',
          description: 'routes.cateringDescription',
          key: paths.catering.pathname,
          icon: <CoffeeOutlined />,
          id: paths.catering.pathname,
          onClick: () => navigateTo(paths.catering.pathname),
        },
        {
          label: translate('routes.reports'),
          title: 'routes.reports',
          description: 'routes.reportsDescription',
          key: paths.reports.pathname,
          icon: <GraphIcon />,
          id: paths.reports.pathname,
          onClick: () => navigateTo(paths.reports.pathname),
        },
      ]
    : [
        {
          label: translate('routes.mobilePriorities'),
          title: 'routes.mobilePriorities',
          description: 'routes.mobilePrioritiesDescription',
          key: paths.mobilePriorities.pathname,
          icon: <HomeIcon />,
          id: paths.mobilePriorities.pathname,
          onClick: () => navigateTo(paths.mobilePriorities.pathname),
        },
        {
          label: translate('routes.dashboard'),
          title: 'routes.dashboard',
          description: 'routes.dashboardDescription',
          key: paths.home.pathname,
          icon: <ActivityIcon />,
          id: paths.home.pathname,
          onClick: () => navigateTo(paths.home.pathname),
        },
        {
          label: translate('routes.businessCategory'),
          title: 'routes.businessCategory',
          description: 'routes.businessCategory',
          key: paths.businessCategory.pathname,
          icon: <CategoryIcon />,
          id: paths.businessCategory.pathname,
          onClick: () => navigateTo(paths.businessCategory.pathname),
        },
        {
          label: translate('routes.business'),
          title: 'routes.business',
          description: 'routes.businessDescription',
          key: paths.business.pathname,
          icon: <BuyIcon />,
          id: paths.business.pathname,
          onClick: () => navigateTo(paths.business.pathname),
        },
        {
          label: translate('routes.entities'),
          title: 'routes.entities',
          description: 'routes.entitiesDescription',
          key: paths.entities.pathname,
          icon: <WorkIcon />,
          id: paths.entities.pathname,
          onClick: () => navigateTo(paths.entities.pathname),
        },
        {
          label: translate('routes.employees'),
          title: 'routes.employees',
          description: 'routes.employeesDescription',
          key: paths.employees.pathname,
          icon: <UserIcon />,
          id: paths.employees.pathname,
          onClick: () => navigateTo(paths.employees.pathname),
        },
        {
          label: translate('routes.entityAdmins'),
          title: 'routes.entityAdmins',
          description: 'routes.entityAdminsDescription',
          key: paths.entityAdmins.pathname,
          icon: <LockIcon />,
          id: paths.entityAdmins.pathname,
          onClick: () => navigateTo(paths.entityAdmins.pathname),
        },
        {
          label: translate('routes.giftcards'),
          title: 'routes.giftcards',
          description: 'routes.giftcardsDescription',
          key: paths.giftcards.pathname,
          icon: <TicketIcon />,
          id: paths.giftcards.pathname,
          onClick: () => navigateTo(paths.giftcards.pathname),
        },
        {
          label: translate('routes.catering'),
          title: 'routes.catering',
          description: 'routes.cateringDescription',
          key: paths.catering.pathname,
          icon: <CoffeeOutlined />,
          id: paths.catering.pathname,
          onClick: () => navigateTo(paths.catering.pathname),
        },
        {
          label: translate('routes.advertising'),
          title: 'routes.advertising',
          description: 'routes.advertisingDescription',
          key: paths.advertising.pathname,
          icon: <AdvertisingOutlined />,
          id: paths.advertising.pathname,
          onClick: () => navigateTo(paths.advertising.pathname),
        },
        {
          label: translate('routes.reports'),
          title: 'routes.reports',
          description: 'routes.reportsDescription',
          key: paths.reports.pathname,
          icon: <GraphIcon />,
          id: paths.reports.pathname,
          onClick: () => navigateTo(paths.reports.pathname),
        },
        {
          label: translate('routes.notifications'),
          title: 'routes.notifications',
          description: 'routes.notificationsDescription',
          key: paths.notifications.pathname,
          icon: <NotificationsIcon />,
          id: paths.notifications.pathname,
          onClick: () => navigateTo(paths.notifications.pathname),
        },
        {
          label: translate('routes.joinUs'),
          title: 'routes.joinUs',
          description: 'routes.joinUsDescription',
          key: paths.joinUs.pathname,
          icon: <JoinUsIcon />,
          id: paths.joinUs.pathname,
          onClick: () => navigateTo(paths.joinUs.pathname),
        },
        {
          label: translate('routes.contactUs'),
          title: 'routes.contactUs',
          description: 'routes.contactUsDescription',
          key: paths.contactUs.pathname,
          icon: <ContactUsIcon />,
          id: paths.contactUs.pathname,
          onClick: () => navigateTo(paths.contactUs.pathname),
        },
        {
          label: translate('routes.settings'),
          title: 'routes.settings',
          description: 'routes.settingsDescription',
          key: paths.settings.pathname,
          icon: <SettingsIcon />,
          id: paths.settings.pathname,
          onClick: () => navigateTo(paths.settings.pathname),
        },
      ];
  const handleBreadcrumbClick = (match: BreadcrumbMatch<string>) => {
    navigate(match.pathname);
  };

  useEffect(() => {
    setSelectedMenuItem(items.find(i => i.key.includes(pathname.split('/')[1])));
  }, [pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <CssBaseline />
      {matchUpMd ? (
        selectedMenuItem && (
          <AppBar open={open} position={'fixed'} style={{ zIndex: 1200 }}>
            <Toolbar>
              <React.Fragment>
                <Box width={open ? drawerWidth : '120px'}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Hidden mdDown>
                      <Grid item>
                        <O2ELogo style={{ width: '50px', height: '50px', margin: '5px' }} />
                      </Grid>
                    </Hidden>
                    <Hidden mdUp>
                      <Grid item>
                        <IconButton
                          edge="start"
                          className={classes.menuButton}
                          color="inherit"
                          aria-label="open drawer"
                          onClick={handleDrawerToggle}
                          size="large"
                        >
                          <MenuTwoToneIcon className={classes.menuIcon} />
                        </IconButton>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Box>

                <Box>
                  <Typography variant="h6" color={'primary.dark'} sx={{ fontWeight: 600 }}>
                    {translate(selectedMenuItem?.title)}
                  </Typography>
                  <Typography color={'gray'}>{translate(selectedMenuItem?.description)}</Typography>
                </Box>
                <div style={{ flexGrow: 1 }} />

                <Button variant={'text'} color={'info'} startIcon={<PublicIcon />} onClick={handleLocaleChange}>
                  {theme.direction === 'rtl' ? 'En' : 'ع'}
                </Button>
                <IconButton
                  sx={{
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    border: 'solid 1px rgba(0, 0, 0, 0.1)',
                    color: 'transparent',
                  }}
                >
                  <ProfileIcon />
                </IconButton>
                <Button
                  id="basic-button"
                  endIcon={<ExpandMoreIcon />}
                  aria-controls={openMenu ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  sx={{
                    justifyContent: 'start',
                  }}
                  aria-expanded={openMenu ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <Grid direction={'column'} justifyItems={'start'}>
                    <Typography component={'div'}>{account?.firstName + ' ' + account?.lastName}</Typography>
                    <Typography component={'div'} fontSize={'small'} color={'gray'}>
                      {account?.authorities?.includes('ROLE_ADMIN') ? 'Administration' : 'Entity Admin'}
                    </Typography>
                  </Grid>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem component="div" onClick={logout}>
                    <Translate contentKey={'global.menu.account.logout'} />
                  </MenuItem>
                </Menu>
              </React.Fragment>
            </Toolbar>
          </AppBar>
        )
      ) : (
        <AppBarMobile position={'fixed'} style={{ zIndex: 1200 }}>
          <Toolbar>
            <React.Fragment>
              <Box width={drawerWidth}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Hidden mdDown>
                    <Grid item>
                      <O2ELogo style={{ width: '50px', height: '50px', margin: '5px' }} />
                    </Grid>
                  </Hidden>
                  <Hidden mdUp>
                    <Grid item>
                      <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        size="large"
                      >
                        <MenuTwoToneIcon className={classes.menuIcon} />
                      </IconButton>
                    </Grid>
                  </Hidden>
                </Grid>
              </Box>

              <div style={{ flexGrow: 1 }} />
              <Button variant={'text'} color={'info'} startIcon={<PublicIcon />} onClick={handleLocaleChange}>
                {theme.direction === 'rtl' ? 'En' : 'ع'}
              </Button>
              <IconButton
                sx={{
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  border: 'solid 1px rgba(0, 0, 0, 0.1)',
                  color: 'transparent',
                }}
              >
                <ProfileIcon />
              </IconButton>
              <Button
                id="basic-button"
                endIcon={<ExpandMoreIcon />}
                aria-controls={openMenu ? 'basic-menu' : undefined}
                aria-haspopup="true"
                sx={{
                  justifyContent: 'start',
                }}
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={handleClick}
              >
                <Grid direction={'column'} justifyItems={'start'}>
                  <Typography component={'div'}>{account?.firstName + ' ' + account?.lastName}</Typography>
                  <Typography component={'div'} fontSize={'small'} color={'gray'}>
                    {account?.authorities?.includes('ROLE_ADMIN') ? 'Administration' : 'Entity Admin'}
                  </Typography>
                </Grid>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem component="div" onClick={logout}>
                  <Translate contentKey={'global.menu.account.logout'} />
                </MenuItem>
              </Menu>
            </React.Fragment>
          </Toolbar>
        </AppBarMobile>
      )}
      <nav className={classes.drawer} aria-label="mailbox folders">
        {matchUpMd ? (
          <Drawer open={open} variant={'permanent'}>
            <DrawerHeader>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <O2ELogo style={{ width: '50px', height: '50px', margin: '5px' }} />
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      color: 'transparent',
                      zindex: 5,
                      ...(open && { display: 'none' }),
                    }}
                  >
                    {theme.direction === 'rtl' ? <ChevronLeftIcon color="primary" /> : <ChevronRightIcon color="primary" />}
                  </IconButton>
                  <IconButton
                    sx={{
                      color: 'transparent',
                      ...(!open && { display: 'none' }),
                    }}
                    onClick={handleDrawerClose}
                  >
                    {theme.direction === 'rtl' ? <ChevronRightIcon color="primary" /> : <ChevronLeftIcon color="primary" />}
                  </IconButton>
                </Grid>
              </Grid>
            </DrawerHeader>

            <List
              subheader={
                <Typography
                  sx={{
                    py: 2.5,
                  }}
                  color={'gray'}
                ></Typography>
              }
            >
              {items.map((item, index) => (
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    selected={selectedMenuItem?.key === item.key}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick={() => navigateTo(item.key)}
                  >
                    <ListItemIcon
                      sx={{
                        color: '#37483F',
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText secondary={item.label} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
        ) : (
          <DrawerMobile
            classes={{ paper: classes.drawerPaperLight }}
            open={drawerOpen}
            onClose={handleDrawerToggle}
            variant={'temporary'}
            anchor="left"
            ModalProps={{ keepMounted: true }}
          >
            <DrawerHeader>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <O2ELogo style={{ width: '50px', height: '50px', margin: '5px' }} />
                </Grid>
              </Grid>
            </DrawerHeader>
            <List>
              {items.map((item, index) => (
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    selected={selectedMenuItem?.key === item.key}
                    sx={{
                      minHeight: 48,
                      justifyContent: 'initial',
                      px: 2.5,
                      ...(selectedMenuItem?.key === item.key && {
                        background: '#B18758 0% 0% no-repeat padding-box',
                        borderRadius: '6px 0px 0px 6px',
                        opacity: 1,
                      }),
                    }}
                    onClick={() => navigateTo(item.key)}
                  >
                    <ListItemIcon
                      sx={{
                        color: '#37483F',
                        minWidth: 0,
                        mr: 3,
                        justifyContent: 'center',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText secondary={item.label} sx={{ opacity: 1 }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </DrawerMobile>
        )}
      </nav>
      <main className={clsx(classes.content, { [classes.contentShift]: drawerOpen })}>
        <div className={classes.toolbar} />
        <div className={classes.main}>
          <Breadcrumbs
            sx={{ pt: 3 }}
            separator={theme.direction === 'rtl' ? <NavigateBeforeIcon fontSize="small" /> : <NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {pathname !== '/home' && (
              <Link
                underline="hover"
                key={'home'}
                sx={{
                  hover: 'cursor-pointer',
                }}
                onClick={() => navigateTo('/home')}
              >
                <Translate contentKey={'routes.home'} />
              </Link>
            )}
            {breadcrumbs?.map(({ match, breadcrumb, key }, index) => {
              const isLast = breadcrumbs.length <= index + 1;

              return (
                <Link
                  underline="hover"
                  key={key}
                  sx={{
                    ...(!isLast && {
                      hover: 'cursor-pointer',
                    }),
                  }}
                  onClick={() => !isLast && handleBreadcrumbClick(match)}
                >
                  {breadcrumb}
                </Link>
              );
            })}
          </Breadcrumbs>
          {children}
        </div>
      </main>
    </Box>
  );
};
export { Layout };
